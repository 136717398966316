import { graphql, Link } from 'gatsby';
import React, { Fragment, ReactNode } from 'react';
import { generateUrlForStates } from '../../../../utils';
import BaseComponent from '../../../base/base-component';
import { ICycleScoreChartPoint } from '../../../components/charts/cycle-score/cycle-score-interface';
import ContainerBox from '../../../components/container-box/container-box';
import CriteriaGrade from '../../../components/criteria-grade/criteria-grade';
import ShareContainer from '../../../components/share-container/share-container';
import SocialMetadata from '../../../components/social-metadata/social-metadata';
import { SocialMetadataType } from '../../../components/social-metadata/social-metadata-enum';
import SvgIcon from '../../../components/svg-icon/svg-icon';
import { SvgIconPathCommand } from '../../../components/svg-icon/svg-icon-enum';
import {
  SODComponents,
  SODCycles,
  SODGrade,
  SODMeasures,
} from '../../../global/global-enum';
import { ISODNode } from '../../../global/global-interfaces';
import { Route } from '../../../global/route-enum';
import { IEmbeddedCompetitionStateScore } from './competition-state-score-interface';

export default class EmbeddedPCompetitionStateScore extends BaseComponent<IEmbeddedCompetitionStateScore> {
  protected declareTranslateCollection(): string | undefined {
    return 'stateCompetitionTemplate';
  }

  public render(): ReactNode {
    const { competitionTotal } = this.props.data;

    const { value: averageValue } = this.populateAverageData(
      SODComponents.TOTAL
    );
    const {
      state,
      value: overallValue,
      score,
      grade,
    } = this.populateOverallData(SODComponents.TOTAL);
    const totalCycleScores: ICycleScoreChartPoint[] =
      competitionTotal.nodes.map((node) => {
        const { cycle, score, weight } = node;
        return {
          cycle,
          score: Math.round(score * 100),
          weight,
        };
      });

    return (
      <Fragment>
        <SocialMetadata
          type={SocialMetadataType.SUMMARY}
          title={`${state} ${this.translate('scoreTitle')}`}
          description={this.translate('firstScoreDescription')}
          imageResourceFileName={`embedded-competition-score-${generateUrlForStates(
            state
          )}.png`}
        />
        <ContainerBox className="c-section--embed">
          <ShareContainer
            className="c-embed__wrap"
            shareName={`competition-score-${generateUrlForStates(state)}`}
          >
            <div className="c-embed__box">
              <div className="c-embed__state">{state}</div>
              <CriteriaGrade
                title={this.translate('scoreTitle')}
                averageScore={Math.round(averageValue * 100)}
                averagePercent={Math.round(averageValue * 100)}
                overallPercentage={Math.round(overallValue * 100)}
                cycleScores={totalCycleScores}
                overallGrade={grade}
                overallScore={Math.round(score * 100)}
              />
            </div>
            <div className="c-embed__logo">
              <Link to={Route.HOME}>
                <SvgIcon
                  width="111"
                  viewBox="0 0 208 44"
                  paths={[
                    {
                      command: SvgIconPathCommand.STATE_OF_DEMOCRACY,
                      fill: '#0D0C0D',
                    },
                    {
                      fillRule: 'evenodd',
                      clipRule: 'evenodd',
                      command: SvgIconPathCommand.LOGO_EAST,
                      fill: '#C7AFF0',
                    },
                    {
                      fillRule: 'evenodd',
                      clipRule: 'evenodd',
                      command: SvgIconPathCommand.LOGO_WEST,
                      fill: '#4A1D96',
                    },
                  ]}
                />
              </Link>
            </div>
          </ShareContainer>
        </ContainerBox>
      </Fragment>
    );
  }

  private populateOverallData(component: SODComponents): ISODNode {
    const { overall } = this.props.data;
    const componentOverall: ISODNode = overall.nodes.find((node) => {
      return node.component === component;
    }) ?? {
      code: 'NA',
      component,
      cycle: SODCycles.OVERALL,
      grade: SODGrade.GRADE_NA,
      measure: SODMeasures.PARTICIPATION,
      score: -1,
      state: 'NA',
      value: -1,
      weight: -1,
    };

    return componentOverall;
  }

  private populateAverageData(component: SODComponents): ISODNode {
    const { average } = this.props.data;
    const componentAverage: ISODNode = average.nodes.find((node) => {
      return node.component === component;
    }) ?? {
      code: 'NA',
      component,
      cycle: SODCycles.OVERALL,
      grade: SODGrade.GRADE_NA,
      measure: SODMeasures.PARTICIPATION,
      score: -1,
      state: 'NA',
      value: -1,
      weight: -1,
    };
    return componentAverage;
  }
}

export const query = graphql`
  query EmbeddedStateCompetition($state: String) {
    competitionTotal: allSheetDataCompetition(
      filter: {
        state: { eq: $state }
        component: { eq: "Total" }
        cycle: { ne: "Overall" }
      }
    ) {
      nodes {
        code
        cycle
        grade
        score
        state
        measure
        component
        value
        weight
      }
    }

    average: allSheetDataCompetition(
      filter: { state: { eq: "US Average" }, cycle: { eq: "Overall" } }
    ) {
      nodes {
        code
        state
        grade
        component
        measure
        cycle
        score
        value
        weight
      }
    }

    overall: allSheetDataCompetition(
      filter: { state: { eq: $state }, cycle: { eq: "Overall" } }
    ) {
      nodes {
        code
        state
        grade
        component
        measure
        cycle
        score
        value
        weight
      }
    }
  }
`;
